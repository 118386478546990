import React, { Component } from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import ContactForm from '../components/parts/ContactForm'
import ContactInformation from '../components/parts/ContactInformation'
import Masonry from 'react-masonry-component';
import data from '../data/content.json'

export default class drinkware extends Component {
    state = {
        productPages: null
    }

    componentDidMount()  {
        window.scrollTo(0,0);
        if (data) {
            this.setState({
                productPages: data.productPages
            });
        }
    }

    render() {

        const { productPages } = this.state;
        
        if (productPages === null) return <div className="home-loading">loading...</div> 
        return (
            <Layout>
                <SEO title={productPages[1].label} description={productPages[1].description} />
                <div className="contact-wrap">
                    <h1 className="page-title">{productPages[1].label}</h1>
                    <h2 className="page-subtitle">{productPages[1].description}</h2>
                    <section>
                        <Masonry
                            className={'my-gallery-class'} // default ''
                            elementType={'div'} // default 'div'
                            disableImagesLoaded={false} // default false
                            updateOnEachImageLoad={false} // default false and products only if disableImagesLoaded is false
                            // imagesLoadedOptions={imagesLoadedOptions} // default {}
                        >
                            {productPages[1].imgSrc.map( (work, idx) => {
                                return (
                                    <div className="products-img" key={idx}>
                                        <img src={`/images/${work}`} alt={`${work} product sample`} />
                                    </div>
                                )
                            })}
                        </Masonry>
                    </section>
                    <section className="products-contact-form">
                        <h1 className="page-title">Like what you see?</h1>
                        <h2 className="page-subtitle">Request a quote or ask us anything!</h2>
                        <ContactForm />
                    </section>
                    <section>
                        <ContactInformation />
                    </section>
                </div>
            </Layout>
        )
    }
}